"use strict";

angular.module("informaApp")
    .service("ToastrHelper", function() {
        var defaultOptions = {
            closeDuration: 1000,
            closeEasing: 'swing',
            closeMethod: 'fadeOut',
            positionClass: "toast-top-right",
            timeOut: 10000
        };

        function setOptions(options) {
            window.toastr.options = _.merge(defaultOptions, options);
        }
        return {
            showSuccess: function(text, options){
                setOptions(options);

                window.toastr.success(text);
            },

            showWarning: function(text, options){
                setOptions(options);

                window.toastr.warning(text);
            },

            showError: function(text, options){
                setOptions(options);

                window.toastr.error(text);
            }
        };
    });
